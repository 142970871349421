import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from './pages/login'; 
import Introducao from './pages/introducao';
import Laudos from './pages/laudos';
import AdminPage from './pages/adminPage';
import UsuarioPerfil from './pages/usuarioPerfil';
import RecuperarSenha from './pages/recuperarSenha';
import ProtectedRoute from './componets/protectedRoute';

axios.interceptors.request.use(
    (config) => {
        const userData = JSON.parse(localStorage.getItem('admin')) || JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('admin')) || JSON.parse(sessionStorage.getItem('user'));
        const token = userData ? userData.token : null;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // Adicionar o token ao cabeçalho
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Interceptor de resposta para redirecionar em caso de acesso expirado
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 403) {
            alert('Acesso Expirado. Por favor, renove sua assinatura.');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);


const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = () => {
            const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
            const admin = JSON.parse(localStorage.getItem('admin')) || JSON.parse(sessionStorage.getItem('admin'));
            return { user, admin };
        };

        const { user, admin } = checkAuthentication();

        if (admin || user) {
            // Você pode adicionar lógica adicional se necessário
        }
    }, [navigate]);

    return (
        <Routes>
            <Route path="/" element={<Introducao />} />
            <Route path="/login" element={<Login />} />
            <Route path='/recuperarSenha' element={<RecuperarSenha />} />
            <Route path="/laudos" element={
                <ProtectedRoute allowedRoles={['user', 'admin']}>
                    <Laudos />
                </ProtectedRoute>
            } />
            <Route path="/admin" element={
                <ProtectedRoute allowedRoles={['admin']}>
                    <AdminPage />
                </ProtectedRoute>
            } />
            <Route path="/perfil" element={
                <ProtectedRoute allowedRoles={['user', 'admin']}>
                    <UsuarioPerfil />
                </ProtectedRoute>
            } />
        </Routes>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);
