import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login() {
    const [senhaVisivel, setSenhaVisivel] = useState(false);
    const [emailOrUsuario, setEmailOrUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [manterConectado, setManterConectado] = useState(false);
    const [erro, setErro] = useState('');
    const navigate = useNavigate();

    const alternarVisibilidadeDaSenha = () => {
        setSenhaVisivel(!senhaVisivel);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post('https://bplaudos.com.br/api/users/login', { emailOrUsuario, senha });

            const { token, role, dataExpiracao } = res.data;

            if (!token || !role) {
                throw new Error('Dados incompletos retornados do servidor.');
            }

            if (role !== 'admin' && new Date(dataExpiracao) < new Date()) {
                setErro('Pagamento atrasado. Entre em contato com o suporte.');
                return;
            }

            salvarDadosAutenticacao(role, token);

            const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
            const admin = JSON.parse(localStorage.getItem('admin')) || JSON.parse(sessionStorage.getItem('admin'));

            console.log('Dados salvos no localStorage/sessionStorage:', { user, admin });

            if (role === 'admin') {
                navigate('/admin');
            } else if (role === 'user') {
                navigate('/laudos');
            } else {
                console.error('Role desconhecida:', role);
                setErro('Erro inesperado. Entre em contato com o suporte.');
            }
        } catch (err) {
            console.error('Erro ao fazer login:', err.response ? err.response.data : err.message);

            const errorMessage =
                err.response && err.response.data && err.response.data.error
                    ? err.response.data.error
                    : 'Usuário ou senha incorretos. Tente novamente.';
            setErro(errorMessage);
        }
    };

    const salvarDadosAutenticacao = (role, token) => {
        const userData = { token, role };

        localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        localStorage.removeItem('admin');
        sessionStorage.removeItem('admin');

        if (manterConectado) {
            localStorage.setItem(role === 'admin' ? 'admin' : 'user', JSON.stringify(userData));
        } else {
            sessionStorage.setItem(role === 'admin' ? 'admin' : 'user', JSON.stringify(userData));
        }

        console.log('Dados salvos no armazenamento:', { userData, manterConectado });
    };


    return (
        <div className="login-body">
            <header className="login-header">
                <img src={logo} alt="Logo" />
                <p>Seja bem-vindo(a)</p>
            </header>

            <main className="login-main">
                <div className="login-introducao">
                    <h1>Login</h1>
                </div>
                <div className="login-formulario">
                    <form onSubmit={handleLogin}>
                        <div className="login-formulario-usuario">
                            <label htmlFor="usuario">Usuário</label>
                            <input
                                type="text"
                                id="usuario"
                                placeholder="Digite seu usuário ou email"
                                value={emailOrUsuario}
                                onChange={(e) => setEmailOrUsuario(e.target.value)}
                            />
                        </div>
                        <div className="login-formulario-senha">
                            <label htmlFor="senha">Senha</label>
                            <div className="login-formulario-senha-input">
                                <input
                                    type={senhaVisivel ? 'text' : 'password'}
                                    id="senha"
                                    placeholder="Digite sua senha"
                                    value={senha}
                                    onChange={(e) => setSenha(e.target.value)}
                                />
                                <button
                                    type="button"
                                    onClick={alternarVisibilidadeDaSenha}
                                    aria-label={senhaVisivel ? 'Esconder senha' : 'Mostrar senha'}
                                >
                                    <FontAwesomeIcon icon={senhaVisivel ? faEyeSlash : faEye} />
                                </button>
                            </div>
                            {erro && (
                                <div className="login-formulario-alerta-erro">
                                    <p>{erro}</p>
                                </div>
                            )}
                        </div>
                        <div className="login-formulario-add">
                            <div className="login-formulario-add-manterC">
                                <input
                                    type="checkbox"
                                    id="manterConectado"
                                    checked={manterConectado}
                                    onChange={(e) => setManterConectado(e.target.checked)}
                                />
                                <label htmlFor="manterConectado">Manter Conectado</label>
                            </div>
                            {/*<div className="login-formulario-add-EsqueceuS">
                                <a
                                    href="#"
                                    style={{
                                        pointerEvents: "none",
                                        cursor: "not-allowed",
                                        textDecoration: "none",
                                        color: "#6F6F6F"
                                    }}
                                    title="Temporariamente bloqueado"
                                >
                                    Esqueceu a senha
                                </a>
                            </div>*/}

                        </div>
                        <div className="login-btn-entrar">
                            <button type="submit">Entrar</button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
}

export default Login;
