import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

function ProtectedRoute({ children, allowedRoles }) {
    const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
    const admin = JSON.parse(localStorage.getItem('admin')) || JSON.parse(sessionStorage.getItem('admin'));

    const currentUser = user || admin;

    const [isValidSession, setIsValidSession] = useState(true);

    useEffect(() => {
        const verifySession = async () => {
            console.log('Verificando sessão para usuário:', currentUser);
    
            if (currentUser && currentUser.token) {
                try {
                    const response = await axios.get('https://bplaudos.com.br/api/users/profile', {
                        headers: {
                            'Authorization': `Bearer ${currentUser.token}`
                        }
                    });
    
                    console.log('Resposta do perfil:', response.data);
    
                    const { dataExpiracao } = response.data;
    
                    if (new Date(dataExpiracao) < new Date()) {
                        console.log('Sessão expirada. Limpando armazenamento...');
                        setIsValidSession(false);
                        localStorage.removeItem('user');
                        sessionStorage.removeItem('user');
                        localStorage.removeItem('admin');
                        sessionStorage.removeItem('admin');
                    }
                } catch (error) {
                    console.error('Erro ao verificar sessão:', error.response?.data || error.message);
                    setIsValidSession(false);
                }
            } else {
                console.log('Nenhum usuário encontrado no armazenamento.');
                setIsValidSession(false);
            }
        };
    
        verifySession();
    }, [currentUser]);
    

    if (!isValidSession || !currentUser) {
        return <Navigate to="/login" />;
    }

    if (!allowedRoles.includes(currentUser.role)) {
        localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        localStorage.removeItem('admin');
        sessionStorage.removeItem('admin');
        return <Navigate to="/login" />;
    }

    console.log('Current User:', currentUser);
    console.log('Allowed Roles:', allowedRoles);
    console.log('Session Valid:', isValidSession);

    return children;
}

export default ProtectedRoute;
