import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import '../styles/usuarioPerfil.scss';
import logo from '../assets/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


function UsuarioPerfil() {
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [telefone, setTelefone] = useState('');

    const [userId, setUserId] = useState(null);
    const [email, setEmail] = useState('');

    const [novoEmail, setNovoEmail] = useState('');
    const [confirmarNovoEmail, setConfirmarNovoEmail] = useState('');

    const [senha, setSenha] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');

    const [showEmailForm, setShowEmailForm] = useState(false);
    const [showSenhaForm, setShowSenhaForm] = useState(false);

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [animando, setAnimando] = useState(false);
    const [alertaMensagem, setAlertaMensagem] = useState('');

    const [senhaVisivel, setSenhaVisivel] = useState(false);
    const [novaSenhaVisivel, setNovaSenhaVisivel] = useState(false);
    const [confirmarNovaSenhaVisivel, setConfirmarNovaSenhaVisivel] = useState(false);

    const alternarVisibilidadeDaSenha = () => {
        setSenhaVisivel(!senhaVisivel);
    };

    const alternarVisibilidadeDaNovaSenha = () => {
        setNovaSenhaVisivel(!novaSenhaVisivel);
    };

    const alternarVisibilidadeDaConfirmacaoDaNovaSenha = () => {
        setConfirmarNovaSenhaVisivel(!confirmarNovaSenhaVisivel);
    };

    const [profile, setProfile] = useState({
        nome: '',
        sobrenome: '',
        telefone: '',
        email: '',
        senha: ''
    });

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
                if (!user || !user.token) {
                    console.error('Token de autenticação não encontrado');
                    return;
                }

                const headers = {
                    Authorization: `Bearer ${user.token}`
                };

                const response = await axios.get('https://bplaudos.com.br/api/users/profile', { headers });
                const userData = response.data;
                setProfile(userData);
                setEmail(userData.email);
            } catch (error) {
                console.error('Erro ao carregar informações do usuário:', error);
            }
        };

        fetchUserProfile();
    }, []);


    const handleProfileUpdate = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
            if (!user || !user.token) {
                console.error('Token de autenticação não encontrado');
                return;
            }

            const headers = {
                Authorization: `Bearer ${user.token}`
            };

            const response = await axios.put('https://bplaudos.com.br/api/users/update-user-profile', profile, { headers });
            console.log('Perfil atualizado com sucesso:', response.data);

            setAlertaMensagem('Perfil atualizado com sucesso!');
            setMostrarAlerta(true);
            setTimeout(() => {
                setAnimando(true);
                setTimeout(() => {
                    setMostrarAlerta(false);
                    setAnimando(false);
                }, 1000);
            }, 2000);
        } catch (error) {
            console.error('Erro ao atualizar o perfil:', error.response ? error.response.data : error.message);
            setAlertaMensagem('Erro ao atualizar o perfil. Tente novamente.');
            setMostrarAlerta(true);
            setTimeout(() => {
                setAnimando(true);
                setTimeout(() => {
                    setMostrarAlerta(false);
                    setAnimando(false);
                }, 1000);
            }, 2000);
        }
    };



    const mostrarAlertaTemporario = () => {
        setMostrarAlerta(true);
        setAnimando(false);

        setTimeout(() => {
            setAnimando(true);

            setTimeout(() => {
                setMostrarAlerta(false);
                setAnimando(false);
            }, 300);
        }, 2000);
    };

    const handleEmailUpdate = async () => {
        if (novoEmail !== confirmarNovoEmail) {
            setAlertaMensagem('Os emails não coincidem');
            mostrarAlertaTemporario();
            return;
        }

        const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));

        try {
            const headers = {
                Authorization: `Bearer ${user.token}`
            };

            const response = await axios.put('https://bplaudos.com.br/api/users/update-user-email', {
                novoEmail,
                senha
            }, { headers });

            setAlertaMensagem('Email atualizado com sucesso');
            mostrarAlertaTemporario();
            setShowEmailForm(false);
            setEmail(novoEmail);
        } catch (error) {
            console.error('Erro ao atualizar email:', error.response ? error.response.data : error.message);
            setAlertaMensagem('Erro ao atualizar email');
            mostrarAlertaTemporario();
        }
    };



    const handleSenhaUpdate = async () => {
        if (novaSenha !== confirmarNovaSenha) {
            setAlertaMensagem('As senhas não coincidem');
            mostrarAlertaTemporario();
            return;
        }

        const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));

        try {
            const headers = {
                Authorization: `Bearer ${user.token}`
            };

            const response = await axios.put('https://bplaudos.com.br/api/users/update-user-senha', {
                senha,
                novaSenha
            }, { headers });

            setAlertaMensagem('Senha atualizada com sucesso');
            mostrarAlertaTemporario();
            setShowSenhaForm(false);
        } catch (error) {
            console.error('Erro ao atualizar senha:', error.response ? error.response.data : error.message);
            setAlertaMensagem('Erro ao atualizar senha');
            mostrarAlertaTemporario();
        }
    };


    const handleOpenEmailForm = () => {
        setNovoEmail('');
        setConfirmarNovoEmail('');
        setSenha('');
        setShowEmailForm(true);
    };

    const handleOpenSenhaForm = () => {
        setNovaSenha('');
        setConfirmarNovaSenha('');
        setSenha('');
        setShowSenhaForm(true);
    };


    return (
        <>
            {showEmailForm && (
                <div className='formulario-bg'>
                    <div className='formulario-alterar-email'>
                        <div className='formulario-email-titulo'>
                            <h2>Alterar email</h2>
                            <p>Este será o seu novo email de login.</p>
                        </div>
                        <div className='formulario-email-input'>
                            <label htmlFor='novoEmail'>
                                <span>Novo Email</span>
                                <input
                                    type='email'
                                    id='novoEmail'
                                    value={novoEmail}
                                    onChange={(e) => setNovoEmail(e.target.value)}
                                    required
                                />
                            </label>
                            <label htmlFor='confirmarNovoEmail'>
                                <span>Confirme Novo Email</span>
                                <input
                                    type='email'
                                    id='confirmarNovoEmail'
                                    value={confirmarNovoEmail}
                                    onChange={(e) => setConfirmarNovoEmail(e.target.value)}
                                    required
                                />
                            </label>
                            <label htmlFor='senha'>
                                <span>Sua Senha</span>
                                <input
                                    type='password'
                                    id='senha'
                                    value={senha}
                                    onChange={(e) => setSenha(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <div className='formulario-email-btn'>
                            <button className='formulario-email-btn-cancelar' onClick={() => setShowEmailForm(false)}>Cancelar</button>
                            <button className='formulario-email-btn-atualizar' onClick={handleEmailUpdate}>Atualizar</button>
                        </div>
                    </div>
                </div>
            )}
            {showSenhaForm && (
                <div className='formulario-bg'>
                    <div className='formulario-alterar-senha'>
                        <div className='formulario-senha-titulo'>
                            <h2>Alterar senha</h2>
                            <p>Esta será a sua novo senha de login.</p>
                        </div>
                        <div className='formulario-senha-input'>
                            <label htmlFor='senhaAtual'>
                                <span>Sua Senha</span>
                                <div className='senha-input-container'>
                                    <input
                                        type={senhaVisivel ? 'text' : 'password'}
                                        id='senhaAtual'
                                        value={senha}
                                        onChange={(e) => setSenha(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={alternarVisibilidadeDaSenha}
                                        aria-label={senhaVisivel ? 'Esconder senha' : 'Mostrar senha'}
                                    >
                                        <FontAwesomeIcon icon={senhaVisivel ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </label>
                            <label htmlFor='novaSenha'>
                                <span>Nova Senha</span>
                                <div className='senha-input-container'>
                                    <input
                                        type={novaSenhaVisivel ? 'text' : 'password'}
                                        id='novaSenha'
                                        value={novaSenha}
                                        onChange={(e) => setNovaSenha(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={alternarVisibilidadeDaNovaSenha}
                                        aria-label={novaSenhaVisivel ? 'Esconder senha' : 'Mostrar senha'}
                                    >
                                        <FontAwesomeIcon icon={novaSenhaVisivel ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </label>
                            <label htmlFor='confirmarNovaSenha'>
                                <span>Confirme Nova Senha</span>
                                <div className='senha-input-container'>
                                    <input
                                        type={confirmarNovaSenhaVisivel ? 'text' : 'password'}
                                        id='confirmarNovaSenha'
                                        value={confirmarNovaSenha}
                                        onChange={(e) => setConfirmarNovaSenha(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={alternarVisibilidadeDaConfirmacaoDaNovaSenha}
                                        aria-label={confirmarNovaSenhaVisivel ? 'Esconder senha' : 'Mostrar senha'}
                                    >
                                        <FontAwesomeIcon icon={confirmarNovaSenhaVisivel ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </label>
                            <div className='redefinir-senha'>
                            {/*
                                <a
                                    href="#"
                                    style={{
                                        pointerEvents: "none",
                                        cursor: "not-allowed",
                                        textDecoration: "none",
                                        color: "#6F6F6F"
                                    }}
                                    title="Temporariamente bloqueado"
                                >
                                    Redefinir senha
                                </a>
                                */
                            }
                            
                            </div>
                        </div>
                        <div className='formulario-senha-btn'>
                            <button className='formulario-senha-btn-cancelar' onClick={() => setShowSenhaForm(false)}>Cancelar</button>
                            <button className='formulario-senha-btn-atualizar' onClick={handleSenhaUpdate}>Atualizar</button>
                        </div>
                    </div>
                </div>
            )}


            {mostrarAlerta && (
                <div
                    id="alert-container"
                    className={`alert ${animando ? "fade-out" : ""}`}
                >
                    <div className="alert-content">
                        <p>{alertaMensagem}</p>
                    </div>
                </div>
            )}

            <div className='perfil-body'>
                <header className='perfil-header'>
                    <a href='/laudos'><img src={logo} alt="Logo" /></a>
                </header>
                <main className='perfil-main'>
                    <div className='perfil-introducao'>
                        <h1>Conta</h1>
                        <p>Veja e edite suas informações pessoais</p>
                    </div>
                    <div className='perfil-info-pessoal-conteiner'>
                        <div className='perfil-info-pessoal-titulo'>
                            <h2>Informações pessoais</h2>
                            <p>Atualize suas informações pessoais.</p>
                        </div>
                        <div className='perfil-info-pessoal-input'>
                            <label id="nomel" htmlFor="nome">
                                <span>Nome</span>
                                <input
                                    type='text'
                                    id='nome'
                                    value={profile.nome}
                                    onChange={(e) => setProfile({ ...profile, nome: e.target.value })}
                                />
                            </label>
                            <label id="sobrenomel" htmlFor='sobrenome'>
                                <span>Sobrenome</span>
                                <input
                                    type='text'
                                    id='sobrenome'
                                    value={profile.sobrenome}
                                    onChange={(e) => setProfile({ ...profile, sobrenome: e.target.value })}
                                />
                            </label>
                            <label id="telefonel" htmlFor='telefone'>
                                <span>Telefone</span>
                                <input
                                    type='text'
                                    id='telefone'
                                    value={profile.telefone}
                                    onChange={(e) => setProfile({ ...profile, telefone: e.target.value })}
                                />
                            </label>
                        </div>
                        <div className='perfil-info-pessoal-btn'>
                            <button onClick={handleProfileUpdate}>Atualizar</button>
                        </div>
                    </div>
                    <div className='perfil-info-login-conteiner'>
                        <div className='perfil-info-login-titulo'>
                            <h2>Informações de login</h2>
                            <p>Veja e Atualize seu email e senha de login.</p>
                        </div>
                        <div className='perfil-info-login-input'>
                            <p>Email:</p>
                            <p>{email}</p>
                            <button onClick={handleOpenEmailForm}>Alterar email</button>

                            <p>Senha:</p>
                            <p>*********</p>
                            <button onClick={handleOpenSenhaForm}>Alterar senha</button>

                        </div>
                    </div>
                </main>
            </div>
        </>
    );

}

export default UsuarioPerfil;
